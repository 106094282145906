const NAVIGATION_BUTTONS = [
    {
        label: "Experience",
        to: "/experiences"
    },
    {
        label: "Skills",
        to: "/skills"
    },
    {
        label: "Resume",
        to: "/resume"
    }
];

export default NAVIGATION_BUTTONS;